import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRollLimit from '../components/BlogRollLimit'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/img/vit-lake.jpg)`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            boxShadow:
              '#f89406 0.5rem 0px 0px, #f89406 -0.5rem 0px 0px',
            backgroundColor: '#f89406',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {title}
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            boxShadow:
              '#f89406 0.5rem 0px 0px, #f89406 -0.5rem 0px 0px',
            backgroundColor: '#f89406',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {subheading}
        </h3>
      </div>
    </div>
    <section className="section columns">
      <div className="column is-10 is-offset-1">
      <div className="mb-5">
            <a href="https://vitspot.com/timetable-calendar/">
              <div className="notification is-dark">
                <p className="is-size-5 has-text-centered">VIT Timetable to Google Calendar</p>
              </div>
            </a>
          </div>
          <div className="mb-5">
            <a href="https://vitspot.com/class-groups">
              <div className="notification is-dark">
                <p className="is-size-5 has-text-centered">VIT Class WhatsApp Group Links</p>
              </div>
            </a>
          </div>
          <div>
            {/* <h3 className="has-text-weight-semibold is-size-3">
              Recent Posts
            </h3> */}
            <BlogRollLimit />
            <div className="has-text-centered">
              <Link className="btn" to="/posts">
                Read more
              </Link>
            </div>
          </div>
      </div>
    </section>
    {/* <nav className="level my-5">
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Instagram Followers</p>
          <p className="title">30,000</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Facebook Likes</p>
          <p className="title">7,000</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Downloads</p>
          <p className="title">15,000</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Website Hits</p>
          <p className="title">5,000,000</p>
        </div>
      </div>
    </nav> */}
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
      }
    }
  }
`
