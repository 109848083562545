import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

class BlogRollLimit extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        <div className="column is-4">
          <Link
            className="title has-text-primary is-size-4"
            to={`/contact`}
          >
            <div class="card">
              <div class="card-image">
                  <img src={'https://cdn.vitspot.com/assets/web/Add-Article.jpg'} style={{ objectFit: 'cover' }} alt={`Add Your Article`} title={`Add Your Article`} />
              </div>
              <div class="card-content">
                <p class="title is-4">{`Your Article Here`}</p>
                <p class="subtitle is-6">{`Submit Your Article`}</p>
                {/* <p className="is-size-6"><time>{post.frontmatter.date}</time></p> */}
              </div>
            </div>
          </Link>
        </div>
        {posts &&
          posts.map(({ node: post }) => (
            <div className="column is-4" key={post.id}>
              <Link
                className="title has-text-primary is-size-4"
                to={post.fields.slug}
              >
                <div class="card">
                  <div class="card-image">
                      <img src={post.frontmatter.featuredImageUrl || 'img/blog-post-thumbnail.jpg'} style={{ objectFit: 'cover' }} alt={post.frontmatter.title} title={post.frontmatter.title} />
                  </div>
                  <div class="card-content">
                    <p class="title is-4">{post.frontmatter.title}</p>
                    <p class="subtitle is-6">{post.fields?.author?.name}</p>
                    <p className="is-size-6"><time>{post.frontmatter.date}</time></p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
    )
  }
}

BlogRollLimit.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollLimitQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          limit: 5
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
                author {
                  name
                }
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredImageUrl
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRollLimit data={data} count={count} />}
  />
)
